import axios from "axios";
import { BASE_URL } from "../configs";

const URL = BASE_URL + "/service";

export const addService = (data) => {
  return axios.post(`${URL}`, data);
};

export const getAllService = () => {
  return axios.get(`${URL}`);
};

export const editServiceById = (id, status) => {
  const data = {
    status_candidat_service: status,
  };
  return axios.put(`${URL}/${id}`, data);
};

export const deleteCandidatService = (id) => {
  return axios.delete(`${URL}/${id}`);
};
